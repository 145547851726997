
import axios from 'axios';

// インタビューの投稿を取得するた目の処理
if (document.querySelector('.p-case__list')) {
	const url_case = '/wp-json/case/v2/posts';
	//初期表示数
	let perPage = 21;
	const jsTotalPostsNum = $('.p-caseHeading__num .num').text();
	const moreBtnCase = document.getElementById('js-more-btn');

	// 初期データ取得
	fetchData(url_case, perPage, renderPosts);

	// もっと見るボタンを押した時の挙動
	if (moreBtnCase) {
		moreBtnCase.addEventListener('click', () => {
			// 追加で21件ずつ表示
			perPage += 21;
			createPostItem();
			togglemoreBtnCase(jsTotalPostsNum);
		});
	}

	// 投稿を取得する
	function fetchData(url, perPage, callback) {
		$.ajax({
			url: url,
			type: 'GET',
			dataType: 'json',
			timeout: 10000,
		}).done(data => {
			callback(data);
			// 投稿数がperPageより多い場合はボタンを表示
			if (data.totalPosts > perPage) {
				moreBtnCase.style.display = 'block';
			} else {
				moreBtnCase.style.display = 'none';
			}
		}).fail(error => {
			// エラーハンドリングを行わない
		});
	}

	// 投稿アイテム生成
	function renderPosts(data) {
		const container_case = $('.p-case__list');
		data.posts.forEach(item => {
			const case_li = caseItem(item);
			container_case.append(case_li);
		});
	}

	// 投稿のHTMLを生成する
	function caseItem(item) {
		const interviewVideoText = item.current_blog_id == 1 ? '動画を見る' : 'Watch Video';
		const interviewBtnText = item.current_blog_id == 1 ? '記事を読む' : 'Read Article';
		const suffix = item.current_blog_id == 1 ? '氏' : '';

		return $(`
			<li class="p-case__item js-more-item">
				<div class="p-caseItem__image">
					<div class="p-caseItem__thumb"><img src="${item.thumbnail}"></div>
					<div class="p-caseItem__logo">${item.logo}</div>
				</div>
				<div class="p-caseItem__detail">
					<p class="p-caseItem__text">${item.interviewer_quote}</p>
					<p class="p-caseItem__company">${item.title}</p>
					<p class="p-caseItem__position">
						<span class="position">${item.interviewer_position}</span>
						<span class="name">${item.interviewer_name}</span>${suffix}
					</p>
					<div class="p-caseItem__btnWrap">
						${item.video ?
							`<a class="c-btn__secondary js-modal-trigger" href="javascript:void(0);" data-modal-trigger="modal-video" data-video-url="${item.video_url}" data-video-poster="${item.video_poster || ''}"><span class="btnText">${interviewVideoText}</span></a>` : ''
						}
						${item.has_content ?
							`<a class="c-btn__secondary" href="${item.permalink}">
								<span class="btnText">${interviewBtnText}</span>
							</a>` : ''
						}
					</div>
				</div>
			</li>
		`);
	}

	// 投稿データ取得と表示
	function createPostItem() {
		$('.p-case__list').empty();
		const postType = moreBtnCase.getAttribute('data-type');

		axios.get('/wp-json/myplugin/v1/filter_posts/', {
			params: {
				type: postType,
				per_page: perPage,
			}
		})
		.then(response => {
			// 投稿を描画
			renderPosts(response.data);
		})
		.catch(error => {
			// エラーハンドリングを行わない
		});
	}

	// もっと見るボタンの表示・非表示
	function togglemoreBtnCase(limitedNum) {
		// 投稿数がperPageより多い場合はボタンを表示
		if (limitedNum > perPage) {
			moreBtnCase.style.display = 'block';
		} else {
			moreBtnCase.style.display = 'none';
		}
	}
}
