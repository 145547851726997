const modalOpenBtn = $('.js-modal-trigger');
const modalCloseBtn = $('.js-modal-close');


$(document).on("click", ".js-modal-trigger", function() {
  let _e = $(this);
  let modalTarget = _e.attr('data-modal-trigger');
  let videoUrl = _e.attr('data-video-url');
  let videoPoster = _e.attr('data-video-poster');
  $('#'+modalTarget).toggleClass('is-show');
  let videoElement = $('.l-modal__video');
  videoElement.attr('poster', videoPoster); // 動画のポスター画像を設定
  videoElement.attr('src', videoUrl); // 動画のURLを設定
  videoElement.get(0).play(); // 一時的に再生
});


$(document).on("click", ".js-modal-close", function() {
  let _e = $(this);
  _e.closest('.l-modal').removeClass('is-show');
  $('.l-modal__video').get(0).pause(); // 停止
  $('.l-modal__video').get(0).currentTime = 0; // 再生位置を初期化
});

