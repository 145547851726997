
import axios from 'axios';

// ニュースの投稿を取得するための処理
if (document.querySelector('.p-news__list')) {
	const url_news = '/wp-json/news/v2/posts';
	//初期表示数
	let perPage = 20;
	const jsTotalPostsNum = $('.p-newsHeading__num .num').text();
	const moreBtnNews = document.getElementById('js-more-btn');
	const radios = document.querySelectorAll('.p-news__cat input[type="radio"]');

	// 初期データ取得
	fetchData(url_news, perPage, renderPosts);

	// ラジオボタンがクリックされた時の挙動
	radios.forEach(radio => {
		radio.addEventListener('change', () => {
			perPage = 20;
			filterAllNum();
			createPostItem();
			togglemoreBtnNews(jsTotalPostsNum);
		});
	});

	// もっと見るボタンを押した時の挙動
	if (moreBtnNews) {
		moreBtnNews.addEventListener('click', () => {
			// 追加で20件ずつ表示
			perPage += 20;
			filterAllNum();
			createPostItem();
		});
	}

	// 投稿を取得する
	function fetchData(url, perPage, callback) {
		$.ajax({
			url: url,
			type: 'GET',
			dataType: 'json',
			timeout: 10000,
		}).done(data => {
			callback(data);
			// 投稿数がperPageより多い場合はボタンを表示
			if (data.totalPosts > perPage) {
				moreBtnNews.style.display = 'block';
			} else {
				moreBtnNews.style.display = 'none';
			}
		}).fail(error => {
			console.error('Error:', error);
		});
	}

	// 投稿を生成する
	function renderPosts(data) {
		const container_news = $('.p-news__list');
		data.posts.forEach(item => {
			const news_li = newsItem(item);
			container_news.append(news_li);
		});
	}

	// 投稿のHTMLを生成する
	function newsItem(item) {
		if (item.news_cat.includes('Note')) {
			return $(`
				<li class="p-news__item js-more-item">
					<a class="p-newsItem__link" href="${item.note_url_link}" target="_blank">
						<div class="p-newsItem__box">
							<span class="date">${item.date}</span>
							<span class="cat">${item.news_cat}</span>
						</div>
						<span class="title">${item.title}</span>
					</a>
				</li>
			`);
		} else {
			return $(`
				<li class="p-news__item js-more-item">
					<a class="p-newsItem__link" href="${item.permalink}">
						<div class="p-newsItem__box">
							<span class="date">${item.date}</span>
							<span class="cat">${item.news_cat}</span>
						</div>
						<span class="title">${item.title}</span>
					</a>
				</li>
			`);
		}
	}

		// 投稿数を取得する
	function filterAllNum() {
		const selectedNewsCats = Array.from(radios)
			.filter(radio => radio.checked)
			.map(radio => radio.value)
			.filter(value => value !== 'all');
		const postTypeName = moreBtnNews.getAttribute('data-type');

		axios.get('/wp-json/myplugin/v1/filter_posts/', {
			params: {
				type: postTypeName,
				cats: selectedNewsCats.length > 0 ? selectedNewsCats : null,
				per_page: -1
			}
		})
		.then(response => {
			const filterNum = response.data.selectedPosts;
			$('.p-newsHeading__num .num').text(filterNum);
			togglemoreBtnNews(filterNum);
		})
		.catch(error => {
			console.error('Error:', error);
		});
	}

	// 投稿データ取得と表示
	function createPostItem() {
		$('.p-news__list').empty();
		const selectedNewsCats = Array.from(radios)
			.filter(radio => radio.checked)
			.map(radio => radio.value)
			.filter(value => value !== 'all');
		const postTypeName = moreBtnNews.getAttribute('data-type');

		axios.get('/wp-json/myplugin/v1/filter_posts/', {
			params: {
				type: postTypeName,
				cats: selectedNewsCats.length > 0 ? selectedNewsCats : null,
				per_page: perPage,
			}
		})
		.then(response => {
			renderPosts(response.data);
		})
		.catch(error => {
			console.error('Error:', error);
		});
	}
	// もっと見るボタンの表示・非表示
	function togglemoreBtnNews(limitedNum) {
		// 投稿数がperPageより多い場合はボタンを表示
		if (limitedNum > perPage) {
			moreBtnNews.style.display = 'block';
		} else {
			moreBtnNews.style.display = 'none';
		}
	}
}
