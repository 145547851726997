//当社の特徴ページのkv画像位置を調整するスクリプト
function adjustImagePosition() {
	if (window.innerWidth <= 1023) {
			const h2Element = document.querySelector('.p-advantage__textArea h2');
			const imgElement = document.querySelector('.p-advantage__headingImg');

			if (h2Element && imgElement) {
					const style = window.getComputedStyle(h2Element);
					const h2Height = parseInt(style.height);

					const newTop = h2Height + 20;

					imgElement.style.top = newTop + 'px';
			}
	} else {
			// 画面幅が1023pxを超えた場合、スタイルをリセットする
			const imgElement = document.querySelector('.p-advantage__headingImg');
			if (imgElement) {
					imgElement.style.top = '';
			}
	}
}

// 初回ロード時に実行
adjustImagePosition();

// 画面サイズが変更されたときに実行
window.addEventListener('resize', adjustImagePosition);

