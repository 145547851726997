import Swiper from 'swiper/bundle';

// スライダー
if ($('.js-multipleSlider').length) {
	const sliderCarousel = new Swiper('.js-multipleSlider', {
		autoplay: {
			delay: 3000,
			disableOnInteraction: false, // 矢印をクリックしても自動再生を止めない
		},
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			768: {
				slidesPerView: 1,
				spaceBetween: 0,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 12,
			},
		},
	});
}

