const _menuBtn = $('#js-hamburger')
const _header = $('#js-header')
const _navigation = $('#js-navigation')
const _overlay = $('.l-navigation__overlay')


_menuBtn.click(function(){
	$('html').toggleClass('is-fixed')
	_menuBtn.toggleClass('-show')
	_navigation.toggleClass('-show')
	_overlay.toggleClass('-show')
})

_overlay.click(function(){
	_menuBtn.removeClass('-show')
	_navigation.removeClass('-show')
	_overlay.removeClass('-show')
})

$('a[href^="#"]').on('click', function () {
	_menuBtn.removeClass('-show');
	_navigation.removeClass('-show');
});
