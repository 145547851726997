
import axios from 'axios';

// ポートフォリオの投稿を取得するための処理
if (document.querySelector('.p-portfolio__list')) {
	const url_portfolio = '/wp-json/portfolio/v2/posts';
	//初期表示数
	let perPage = 30;
	const jsTotalPostsNum = $('.p-portfolioHeading__total .num').text();
	const moreBtnPortfolio = document.getElementById('js-more-btn');
	const checkboxes = document.querySelectorAll('.p-portfolioHeading__catList input[type="checkbox"]');
	const orders = document.querySelectorAll('.p-portfolioHeading__order input[type="radio"]');
	const hash = window.location.hash.substring(1); // URLのハッシュ部分を取得

	// 初期データ取得
	fetchData(url_portfolio, perPage, renderPosts, hash);

	// チェックボックスがクリックされた時の挙動
	checkboxes.forEach(checkbox => {
		checkbox.addEventListener('change', () => {
			perPage = 30;
			filterAllNum();
			createPostItem();
			togglemoreBtnPortfolio();
		});
	});

	// 昇順降順がクリックされた時の挙動
	orders.forEach(order => {
		order.addEventListener('click', function() {
			orders.forEach(o => o.parentElement.classList.remove('is-current'));
			this.parentElement.classList.add('is-current');
			filterAllNum();
			createPostItem();
			togglemoreBtnPortfolio(jsTotalPostsNum);
		});
	});

	// もっと見るボタンを押した時の挙動
	if (moreBtnPortfolio) {
		moreBtnPortfolio.addEventListener('click', () => {
			// 追加で30件ずつ表示
			perPage += 30;
			filterAllNum();
			createPostItem();
		});
	}

	// 投稿を取得する
	function fetchData(url, perPage, callback, hash = null) {
		$.ajax({
			url: url,
			type: 'GET',
			dataType: 'json',
			timeout: 10000,
		}).done(data => {
			// 初期表示の重複を防ぐために、コンテナをクリア
			$('.p-portfolio__list').empty();
			callback(data, perPage);
			// 投稿数がperPageより多い場合はボタンを表示
			if (data.totalPosts > perPage) {
				moreBtnPortfolio.style.display = 'block';
			} else {
				moreBtnPortfolio.style.display = 'none';
			}
			// ハッシュが存在する場合の処理
			if (hash) {
				const targetElement = document.getElementById(hash);
				if (targetElement) {
					// 初期表示の中にハッシュが存在する場合はスクロール
					scrollToElement(targetElement);
				} else {
					// 初期表示の中にハッシュが存在しない場合は全件取得してスクロール
					moreBtnPortfolio.style.display = 'none'; // もっと見るボタンを非表示
					perPage = -1; // 全件取得
					$.ajax({
						url: url,
						type: 'GET',
						dataType: 'json',
						data: { all_posts: 'true' }, // 全件取得のためのパラメータ
						timeout: 10000,
					}).done(data => {
						// 全件表示
						callback(data, data.totalPosts);
						// ハッシュと同じIDを持つtargetElementまでスクロール
						const targetElement = document.getElementById(hash);
						if (targetElement) {
							scrollToElement(targetElement);
						}
					}).fail(error => {
						// エラーハンドリングを行わない
					});
				}
			}
		}).fail(error => {
			// エラーハンドリングを行わない
		});
	}

	// スクロール位置を調整する関数
	function scrollToElement(element) {
		const headerOffset = 120; // ヘッダーの高さ分
		const elementPosition = element.getBoundingClientRect().top + window.scrollY;
		const offsetPosition = elementPosition - headerOffset;

		window.scrollTo({
			top: offsetPosition,
			behavior: 'smooth'
		});
	}

	// 投稿を生成する
	function renderPosts(data, perPage) {
		const container_portfolio = $('.p-portfolio__list');
		const postsToRender = data.posts.slice(0, perPage); // 表示する投稿を制限
		postsToRender.forEach(item => {
			const portfolio_li = portfolioItem(item);
			container_portfolio.append(portfolio_li);
		});
	}

	// 投稿のHTMLを生成する
	function portfolioItem(item) {
		const year = item.joined_date.slice(0, 4);
		const month = item.joined_date.slice(4, 6);
		const formattedDate = item.current_blog_id == 1 ? `${year}年${month}月` : `${year}/${month}`;
		const dateText = item.current_blog_id == 1 ? '参画日' : 'Investment date';
		const detailText = item.current_blog_id == 1 ? '事例インタビューはこちら' : 'Click here for the interview article';
		const videoText = item.current_blog_id == 1 ? '事例インタビュー動画はこちら' : 'Click here for success stories and interviews';

		return $(`
			<li class="p-portfolio__item js-more-item" id="${item.id}">
				<div class="p-portfolio__itemHeading">
					<a class="p-portfolio__itemParent" href="portfolio/?portfolio-cat=${item.portfolio_cat_terms}">${item.portfolio_cat}</a>
					<p class="p-portfolio__itemDate">${dateText}：${formattedDate}</p>
				</div>
				${item.url ?
					`<a class="p-portfolio__itemVisual -link" href="${item.url}" target="_blank">` :
					`<span class="p-portfolio__itemVisual">`
				}
					<div class="p-portfolio__itemLogo">
						<img src="${item.logo}">
					</div>
					<div class="p-portfolio__itemCompany">
						<span class="company">${item.title}</span>${item.url ? '<span class="icon"></span>' : ''}
					</div>
				${item.url ? '</a>' : '</span>'}
				<div class="p-portfolio__itemDetail">
					<p class="p-portfolio__itemCat">${item.portfolio_type}</p>
					<p class="p-portfolio__itemText">${item.description}</p>
				</div>
				<div class="p-portfolio__itemBtn">
					${item.interview_exists ?
						(item.interview_content_exists ?
							`<a class="c-btn__secondary" href="${item.interview_url}"><span class="btnText">${detailText}</span></a>` :
							`<a class="c-btn__secondary js-modal-trigger" href="javascript:void(0);" data-modal-trigger="modal-video" data-video-url="${item.video_url}" data-video-poster="${item.video_poster || ''}"><span class="btnText">${videoText}</span></a>`
						) : ''
					}
				</div>
			</li>
		`);
	}

	// 投稿数を取得する
	function filterAllNum() {
		const selectedCats = Array.from(checkboxes)
			.filter(checkbox => checkbox.checked)
			.map(checkbox => checkbox.value);
		const selectedOrder = Array.from(orders).find(order => order.parentElement.classList.contains('is-current')).value;
		const postType = moreBtnPortfolio.getAttribute('data-type');

		axios.get('/wp-json/myplugin/v1/filter_posts/', {
			params: {
				type: postType,
				cats: selectedCats,
				order: selectedOrder,
				per_page: -1
			}
		})
		.then(response => {
			const filterNum = response.data.selectedPosts;
			// 投稿数を表示
			$('.p-portfolioHeading__total .num').text(filterNum);
			// もっと見るボタンの表示・非表示
			togglemoreBtnPortfolio(filterNum);
		})
		.catch(error => {
			// エラーハンドリングを行わない
		});
	}

	// 投稿データ取得と表示
	function createPostItem() {
		$('.p-portfolio__list').empty();
		const selectedCats = Array.from(checkboxes)
			.filter(checkbox => checkbox.checked)
			.map(checkbox => checkbox.value);
		const selectedOrder = Array.from(orders).find(order => order.parentElement.classList.contains('is-current')).value;
		const postType = moreBtnPortfolio.getAttribute('data-type');

		axios.get('/wp-json/myplugin/v1/filter_posts/', {
			params: {
				type: postType,
				cats: selectedCats,
				order: selectedOrder,
				per_page: perPage,
			}
		})
		.then(response => {
			// 投稿を描画
			renderPosts(response.data, perPage);
		})
		.catch(error => {
			// エラーハンドリングを行わない
		});
	}

	// もっと見るボタンの表示・非表示
	function togglemoreBtnPortfolio(limitedNum) {
		// 投稿数がperPageより多い場合はボタンを表示
		if (limitedNum > perPage) {
			moreBtnPortfolio.style.display = 'block';
		} else {
			moreBtnPortfolio.style.display = 'none';
		}
	}
}
