'use strict';

import "@babel/polyfill";
import axios from 'axios';
import Swiper from 'swiper/bundle';
import { ua } from './view/_ua';
import { height } from './view/_height';
import { accordion } from './view/_accordion';
import { header } from './view/_header';
import { slider } from './view/_slider';
import { modal } from './view/_modal';
import { ajax_portfolio } from './view/_ajax_portfolio';
import { ajax_case } from './view/_ajax_case';
import { ajax_news } from './view/_ajax_news';
import { imagePosition } from './view/_imagePosition';

